<template>
    <div>
        <div class="title">打卡方式</div>

        <el-tabs
            style="margin-top: 20px;"
            v-model="activeName"
            @tab-click="handleClick"
        >
            <el-tab-pane label="考勤机打卡" name="考勤机打卡">
                <!-- filter -->
                <div class="filter_box">
                    <div class="filter_search">
                        <el-input
                            class="filter_search_input"
                            placeholder="请输入关键词"
                            v-model="search.keyword"
                            clearable
                        >
                        </el-input>
                        <div class="custom_button no_select">搜索</div>
                    </div>
                    <div class="filter_btns">
                        <div
                            class="custom_button plain no_select"
                            @click="
                                $router.push({ name: 'MachinePunchEditor' })
                            "
                        >
                            添加考勤机
                        </div>
                    </div>
                </div>

                <!-- table -->
                <el-table
                    :data="tableData"
                    :header-cell-style="tableHeaderStyle"
                    :cell-style="tableCellStyle"
                    style="width: 100%;"
                >
                    <el-table-column prop="name" label="名称">
                    </el-table-column>
                    <el-table-column prop="sn_code" label="考勤机型号">
                    </el-table-column>
                    <el-table-column prop="serial_number" label="序列号">
                    </el-table-column>
                    <el-table-column prop="proxy_url" label="代理域名">
                    </el-table-column>
                    <el-table-column label="操作">
                        <div class="operations">
                            <!-- <div>查看</div> -->
                            <div
                                style="color: #4db9d5;"
                                @click="del(scope.row.id, 1)"
                            >
                                删除
                            </div>
                        </div>
                    </el-table-column>
                </el-table>
                <el-pagination
                    layout="prev, pager, next"
                    :total="page.total"
                    :page-size="page.size"
                    :current-page.sync="page.page"
                    @current-change="loadData"
                >
                </el-pagination>
            </el-tab-pane>
            <el-tab-pane label="小程序打卡" name="小程序打卡">
                <!-- filter -->
                <div class="filter_box">
                    <div class="filter_search">
                        <el-input
                            class="filter_search_input"
                            placeholder="请输入关键词"
                            v-model="search.keyword"
                            clearable
                        >
                        </el-input>
                        <div class="custom_button no_select">搜索</div>
                    </div>
                    <div class="filter_btns">
                        <div
                            class="custom_button plain no_select"
                            @click="
                                $router.push({
                                    name: 'MiniprogramPunchWayEditor',
                                })
                            "
                        >
                            添加新地址
                        </div>
                    </div>
                </div>

                <!-- table -->
                <el-table
                    :data="tableData"
                    :header-cell-style="tableHeaderStyle"
                    :cell-style="tableCellStyle"
                    style="width: 100%;"
                >
                    <el-table-column prop="address" label="打卡地址">
                    </el-table-column>
                    <el-table-column prop="model" label="详细定位">
                        <template v-slot:default="scope">
                            <span
                                >{{ scope.row.lat }}, {{ scope.row.lng }}</span
                            >
                        </template>
                    </el-table-column>

                    <el-table-column prop="punch_range" label="打卡范围">
                        <template v-slot:default="scope">
                            <span>{{ scope.row.punch_range }}米</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template v-slot:default="scope">
                            <div class="operations">
                                <div
                                    @click="
                                        $router.push({
                                            name: 'MiniprogramPunchWayEditor',
                                            params: { id: scope.row.id },
                                            query: {
                                                address: scope.row.address,
                                                lat: scope.row.lat,
                                                lng: scope.row.lng,
                                                punch_range:
                                                    scope.row.punch_range,
                                            },
                                        })
                                    "
                                >
                                    编辑
                                </div>
                                <div
                                    style="color: #4db9d5;"
                                    @click="del(scope.row.id, 2)"
                                >
                                    删除
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    layout="prev, pager, next"
                    :total="page.total"
                    :page-size="page.size"
                    :current-page.sync="page.page"
                    @current-change="loadData"
                >
                </el-pagination>
            </el-tab-pane>
            <el-tab-pane label="企微打卡" name="企微打卡">
                <el-form
                    ref="form"
                    :rules="rules"
                    label-position="top"
                    :model="form"
                    label-width="80px"
                >
                    <el-form-item label="设置企微ID" prop="wechat_work_id">
                        <el-input v-model="form.wechat_work_id"></el-input>
                    </el-form-item>

                    <el-form-item
                        label="设置企微打卡应用secret"
                        prop="app_secret"
                    >
                        <el-input v-model="form.app_secret"></el-input>
                    </el-form-item>

                    <el-form-item class="form_btns">
                        <div class="form_btns">
                            <div
                                class="custom_button plain no_select"
                                @click="onSubmit"
                            >
                                保存设置
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            activeName: "1",
            panes: [
                {
                    name: "考勤机打卡",
                    id: 1,
                },
                {
                    name: "小程序打卡",
                    id: 2,
                },
                {
                    name: "企微打卡",
                    id: 3,
                },
            ],
            search: {
                keyword: "",
            },
            page: {
                size: 15,
                page: 1,
                total: 0,
            },
            tableData: [],
            form: {
                wechat_work_id: "",
                app_secret: "",
            },
            rules: {
                wechat_work_id: [
                    {
                        required: true,
                        message: "请填写企微ID",
                        trigger: "blur",
                    },
                ],
                app_secret: [
                    {
                        required: true,
                        message: "请填写企微打卡应用secret",
                        trigger: "blur",
                    },
                ],
            },

            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    created() {
        this.activeName = this.panes[0].name;
        this.loadData((loadDataObj) => {
            loadDataObj.type = this.panes[0].id;
        });

        this.getQwPunchWay()
            .then((res) => {
                console.log(res);
                this.form = res.data.data;
            })
            .then((e) => {
                console.error(e);
            });
    },
    methods: {
        ...mapActions("attendance", [
            "getPunchWayList",
            "delPunchWay",
            "getQwPunchWay",
            "postQwPunchWay"
        ]),

        loadData(func) {
            // 单例初始化
            if (!this.loadDataObj)
                this.loadDataObj = new LoadDataClass(0, this.getPunchWayList);

            // 通知，用于修改对象属性
            func(this.loadDataObj);

            let loading = this.$loading();
            this.loadDataObj
                .load(this.page.page)
                .then((res) => {
                    console.log(res);
                    this.tableData = res.data.data.list;
                    this.page.total = res.data.data.total;
                    loading.close();
                })
                .catch((e) => {
                    loading.close();
                    this.$message.error(e);
                });
        },

        /**
         * 搜索
         */
        toSearch() {
            this.page.page = 1;
            this.loadData((loadDataObj) => {
                loadDataObj.keyword = this.search.keyword;
            });
        },

        handleClick(tab, event) {
            console.log(tab, event);
            this.page.page = 1;
            this.loadData((loadDataObj) => {
                loadDataObj.type = this.panes[tab.index].id;
            });
        },

        /**
         * 删除打卡方式
         */
        del(id, type) {
            this.$confirm("此操作将永久删除该条目, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.delPunchWay({ id, type })
                    .then((res) => {
                        console.log(res);
                        this.$message({
                            type: "success",
                            message: res.data.msg,
                        });
                    })
                    .catch((e) => {
                        this.$message({
                            type: "error",
                            message: e.msg,
                        });
                    });
            });
        },

        /**
         * 企微设置保存
         */
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = this.$loading();
                    console.log(this.form);
                    this.postQwPunchWay(this.form)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.success(res.data.msg);
                        })
                        .catch((e) => {
                            console.error(e);
                            loading.close();
                            this.$message.error(e.msg);
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },
    },
};

class LoadDataClass {
    constructor(type, request, keyword = null) {
        this.type = type;
        this.func = request;
        this.keyword = keyword;
    }

    load(page) {
        let params = {
            type: this.type,
        };
        if (this.keyword) params.keywords = this.keyword;
        params.page = page;
        return this.func(params);
    }
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_input {
            width: 500px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.el-tabs {
    ::v-deep .el-tabs__header {
        margin: 0;
    }

    ::v-deep .el-tabs__item {
        font-size: 16px;
        color: #495060;
        height: 45px;

        &.is-active {
            color: #4db9d5;
        }
    }

    ::v-deep .el-tabs__active-bar {
        background-color: #4db9d5;
    }
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}

::v-deep .el-form-item__label {
    font-size: 14px;
    color: #222222;
}

::v-deep .el-input__inner {
    width: 480px;
}

.el-input {
    width: 400px;
    height: 48px;
    font-size: 14px;
    color: #222222;
}

.form_btns {
    display: flex;
    align-items: center;

    .custom_button {
        width: 142px;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
    }

    > div:not(:first-child) {
        margin-left: 20px;
    }
}
</style>
